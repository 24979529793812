/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch, withRouter, BrowserRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Main from './main';
import Login from './common/login';

const RouteContainer = props => (
  <TransitionGroup>
    <CSSTransition
      key={props.location.key}
      timeout={{ enter: 600, exit: 600 }}
      classNames="fade"
      unmountOnExit
    >
      {/* <BrowserRouter> */}
      <Switch location={props.location}>
        <Route exact path="/" component={Main} />
        {/* <Route exact path="/" component={Login} /> */}

      </Switch>
      {/* </BrowserRouter> */}
    </CSSTransition>
  </TransitionGroup>
);

export default withRouter(RouteContainer);
