/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unused-state */
import React, { createContext } from 'react';
// import { factory } from '../factoryjs/create-factory';

export const UserContext = createContext();

export class UserProvider extends React.Component {
    login = newUserLoggedIn => {
        this.setState({ isUserLoggedIn: newUserLoggedIn })
    };
    logout = newUserLoggedIn => {
        this.setState({ isUserLoggedIn: newUserLoggedIn })
    };
    updatewidgetType = newwidgetType => {
        this.setState({ widgetType: newwidgetType });
    };
    updateSelectedEntity = newSelectedEntity => {
        this.setState({ selectedEntity: newSelectedEntity });
    };
    updateBottomMenuState = thisbottomMenuState => {
        this.setState({ bottomMenuState: thisbottomMenuState });
    };
    updateSceneId = newsceneid => {
        const sceneEl = document.querySelector("a-scene");
        sceneEl.emit('changescene', {
            sceneId: newsceneid
        });
    };
    updateSceneProjectJSON = (newSceneJSON, newpptvrSceneJson) => {
        // const bgId = 'bg' + newpptvrSceneJson.sceneid;
        // this.state.projectJSON.backgrounds[bgId] = {
        //     id: bgId,
        //     type: 'image360',
        //     src: newpptvrSceneJson.bgimage,
        //     defaultSceneOrientation: newpptvrSceneJson.defaultSceneOrientation
        // };
        // this.state.projectJSON.scenes[newSceneJSON.id] = newSceneJSON;
        this.setState({ projectJSON: this.state.projectJSON });
    };
    updateBackgroundJSON = (updatedSceneObj) => {
        // this.state.projectJSON.backgrounds['bg' + updatedSceneObj._id].src = updatedSceneObj.updatedVal.bgimage;
        this.setState({ projectJSON: this.state.projectJSON });
    };
    deleteSceneProjectJSON = (deletedsceneid) => {
        delete this.state.projectJSON.scenes[deletedsceneid];
        this.setState({ projectJSON: this.state.projectJSON });
    };
    // updateProjectJSONonWidgetChange = (updatedObj) => {
    //     this.state.projectJSON.scenes[factory.currentSceneId].widget[updatedObj.id] = updatedObj;
    //     this.setState({ projectJSON: this.state.projectJSON });
    // };
    updateCurrentSceneId = (updatedSceneId) => {
        // this.state.currentSceneId = updatedSceneId;
    };

    updateNotificationMsg = newNotiMsg => {
        this.setState({ notificationMsg: newNotiMsg });
    };
    updateGuruModuleStatus = gurumodulestatus => {
        this.setState({ isGuruModuleEnabled: gurumodulestatus });
    };
    updateUserRoleStatus = userrolestatus => {
        this.setState({ userRole: userrolestatus });
    };
    updateSubscriptionType = subscriptiontype => {
        this.setState({ subscriptionType: subscriptiontype });
    };
    updateSceneSynchronizationStatus = synchronizationstatus => {
        this.setState({ sceneSynchronization: synchronizationstatus });
    };


    state = {
        isUserLoggedIn: false,
        login: this.login,
        logout: this.logout,
        widgetType: 'temptext',
        systemType: 'vr',
        notificationMsg: 'Tested Message',
        selectedEntity: {
            obj: {},
            melzoObj: {},
            type: null,
        },
        isGuruModuleEnabled: false,
        userRole: '',
        subscriptionType: '',
        bottomMenuState: 'SCENE_LIST',
        sceneid: '',
        projectJSON: {},
        currentSceneId: '',
        sceneSynchronization: false,
        updatewidgetType: this.updatewidgetType,
        updateSceneId: this.updateSceneId,
        updateSceneProjectJSON: this.updateSceneProjectJSON,
        deleteSceneProjectJSON: this.deleteSceneProjectJSON,
        updateBackgroundJSON: this.updateBackgroundJSON,
        updateBottomMenuState: this.updateBottomMenuState,
        updateSelectedEntity: this.updateSelectedEntity,
        updateCurrentSceneId: this.updateCurrentSceneId,
        updateNotificationMsg: this.updateNotificationMsg,
        updateGuruModuleStatus: this.updateGuruModuleStatus,
        updateUserRoleStatus: this.updateUserRoleStatus,
        updateSubscriptionType: this.updateSubscriptionType,
        updateSceneSynchronizationStatus: this.updateSceneSynchronizationStatus
    };
    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export const UserConsumer = UserContext.Consumer;