import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCmfiBK6OfuMW5KlxkYzztuvd07VWpcXVw",
    authDomain: "melzo-search.firebaseapp.com",
    databaseURL: "https://melzo-search.firebaseio.com",
    projectId: "melzo-search",
    storageBucket: "melzo-search.appspot.com",
    messagingSenderId: "1099127771403",
    appId: "1:1099127771403:web:0400d21c37c429a135920e"
};
firebase.initializeApp(config);

export default firebase;