/* eslint-disable */
import React from 'react';
import firebase from '../common/firebase';
import CryptoJS from 'crypto-js';
// import { gaSearchFieldAccessed, gaSearchSuggestionSelected } from './analytics';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/search.css';

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.BLUR = 'blur';
        this.FOCUS = 'focus';

        this.state = {
            searchInputStatus: this.BLUR,
            selectedId: ''
        };

        this.onCopyText = this.onCopyText.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount() {
        const client = algoliasearch(
            'SLKRJIRPVC',
            '29622f16da546f07f7dc4f2e3963da29'
        );

        const index = client.initIndex('melzo-discovery');
        autocomplete('#search-input', { hint: false }, [
            {
                source: autocomplete.sources.hits(index, { hitsPerPage: 50 }),
                displayKey: 'projectname',
                templates: {
                    suggestion(suggestion) {

                        const tempvalu = suggestion._highlightResult.name
                        if (typeof tempvalu !== "undefined") {
                            console.log("suggestion : ", tempvalu.value)
                            const val = tempvalu.value;
                            return autocomplete.escapeHighlightedString(val);
                        }
                    }
                }
            }
        ]).on('autocomplete:selected', (event, suggestion) => {
            // const inputFieldValue = document.getElementById('search-input').value;
            // gaSearchSuggestionSelected(inputFieldValue, suggestion.objectID);
            const concatURL = suggestion.objectID + 'vr.melzo.com';
            const encrypted = CryptoJS.AES.encrypt(concatURL, "0DmtozfsHyOlKcbVB4l2sAGJh78HAInz");
            let finalURL = 'https://vr.melzo.com/dekho/' + encrypted.toString().replace(/[/]/g, '_');
            console.log("Selected:: ", finalURL)
            this.setState({
                selectedId: finalURL
            })

            // window.open(`https://melzo.com/dekho/${suggestion.objectID}`, '_self');
        });

        document.querySelector('.search__input').addEventListener('focus', () => {
            // gaSearchFieldAccessed();

            this.setState({
                searchInputStatus: this.FOCUS
            });
        });

        document.querySelector('.search__input').addEventListener('blur', () => {
            this.setState({
                searchInputStatus: this.BLUR
            });
        });

        document.querySelector('#cross-icon').addEventListener('click', () => {
            document.querySelector('.search__input').blur();
            document.querySelector('.search__input').value = '';
        });
    }

    onCopyText() {
        let copyText = document.getElementById("projectUrl");
        if (copyText !== "") {
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            // document.getElementById('projectUrl').value = '';
        }
    }
    onOpenNewTab() {
        let copyText = document.getElementById("projectUrl").value;
        if (copyText !== "") {
            window.open(copyText, '_blank');
        }
    }

    onLogout() {
        firebase.auth().signOut()
    }
    render() {
        const { searchInputStatus } = this.state;
        let searchIconClassList = 'search__icon search__icon-';
        let crossIconClassList = 'search__icon search__icon-cross search__icon-';
        searchIconClassList +=
            searchInputStatus === this.FOCUS ? 'transparent' : 'opaque';
        crossIconClassList +=
            searchInputStatus === this.BLUR ? 'transparent' : 'opaque';
        return (
            <div style={{ width: '100%' }}>

                <div className="row margintopsearch" style={{ width: '100%' }}>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="search__container fbca">
                            <svg
                                id="cross-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                className={`${crossIconClassList} cursor--pointer`}
                                fill="#fff"
                            >
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                            <img
                                className={searchIconClassList}
                                src="https://d2mjqh1irji0ds.cloudfront.net/image/public/discovery/icons/generic/search.svg"
                                alt="cross"
                            />

                            <input
                                id="search-input"
                                className="search__input"
                                type="text"
                                placeholder="Search"
                            />

                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 margintopinput">
                        <div >
                            <input
                                readOnly
                                autoFocus
                                margin="dense"
                                id="projectUrl"
                                name="selectedValue"
                                // label="Project Name"
                                // placeholder="Select Value"
                                type="text"
                                value={this.state.selectedId}
                                className="inputlinkwidth padding inputform"
                                style={{ width: '100%' }}
                            />
                            <div className="flexbtn">
                                <button type="button" onClick={this.onCopyText}>
                                    Copy
                                </button>
                                <button type="button" onClick={this.onOpenNewTab}>
                                    Open in New Tab
                                </button>
                                <button type="button" onClick={this.onLogout}>
                                    Logout
                            </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            // {/* <div className="search ">
            //     <div className="search__container fbca">
            //         <svg
            //             id="cross-icon"
            //             xmlns="http://www.w3.org/2000/svg"
            //             width="24"
            //             height="24"
            //             viewBox="0 0 24 24"
            //             className={`${crossIconClassList} cursor--pointer`}
            //             fill="#fff"
            //         >
            //             <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            //             <path d="M0 0h24v24H0z" fill="none" />
            //         </svg>
            //         <img
            //             className={searchIconClassList}
            //             src="https://d2mjqh1irji0ds.cloudfront.net/image/public/discovery/icons/generic/search.svg"
            //             alt="cross"
            //         />

            //         <input
            //             id="search-input"
            //             className="search__input"
            //             type="text"
            //             placeholder="Search"
            //         />

            //     </div>

            //     <div>
            //         <input
            //             readOnly
            //             autoFocus
            //             margin="dense"
            //             id="projectUrl"
            //             name="selectedValue"
            //             // label="Project Name"
            //             // placeholder="Select Value"
            //             type="text"
            //             value={this.state.selectedId}
            //             className="inputlinkwidth padding inputform"
            //         />
            //     </div>

            //     <div>
            //         <button type="button" onClick={this.onCopyText}>
            //             Copy
            //     </button>
            //     </div>
            //     <div>
            //         <button type="button" onClick={this.onOpenNewTab}>
            //             Open in New Tab
            //     </button>
            //     </div>

            //     <div>
            //         <button type="button" onClick={this.onLogout}>
            //             Logout
            //     </button>
            //     </div>
            // </div> */}

        );
    }
}



export default Search;