/* eslint-disable no-unused-vars */

import React from 'react';
import { UserContext } from '../context/Context'
import firebase from '../common/firebase';
import '../css/login.css';
import 'bootstrap/dist/css/bootstrap.min.css';


class Login extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            // name: '',
            // email: '',
            // password: '',
            // redirect: false,
            redirectaddform: false,
            redirectgoogle: false,
            redirectfacebook: false,
            notiMsg: false,
        }
        this.onAddSubmit = this.onAddSubmit.bind(this);
        this.onSubmitLogin = this.onSubmitLogin.bind(this);
        this.onForgetPassword = this.onForgetPassword.bind(this);

    }


    componentDidMount() {

        const signUpButton = document.getElementById('signUp');

        const signInButton = document.getElementById('login');
        const container = document.getElementById('container');

        signUpButton.addEventListener('click', () => container.classList.add('right-panel-active'));

        signInButton.addEventListener('click', () => container.classList.remove('right-panel-active'));

        firebase.auth().onAuthStateChanged((user) => {

            if (user) {

            } else {
            }
        });
    }

    onAddSubmit = (e) => {
        e.preventDefault();
        let divName = document.getElementById('name-signup');
        let LoderBtn = document.getElementById('btn-loadersignup');
        divName.style.display = 'none';
        LoderBtn.style.display = 'block';

        let regEmail = document.getElementById('reg-email').value;
        let regPassword = document.getElementById('reg-password').value;

        // let { email, password } = this.state;
        let patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (regEmail === "") {
            alert("Please enter Your Email !");
            divName.style.display = 'block';
            LoderBtn.style.display = 'none';
        } else if (regPassword === "") {
            alert("Please enter Your Password !");
            divName.style.display = 'block';
            LoderBtn.style.display = 'none';
        } else {

            if (!patternEmail.test(regEmail)) {
                alert("Please enter a valid Email-id");
                divName.style.display = 'block';
                LoderBtn.style.display = 'none';
            } else {
                firebase.auth().createUserWithEmailAndPassword(regEmail, regPassword).then(() => {
                    // this.setState({
                    //     redirectaddform: true
                    // })

                    regEmail = document.getElementById('reg-email').value = "";
                    regPassword = document.getElementById('reg-password').value = "";
                    // window.location.href = "/main"
                    divName.style.display = 'block';
                    LoderBtn.style.display = 'none';
                })
                    .catch((error) => {
                        console.log("ERROR :: ", error)
                        alert("User already exist.")
                        divName.style.display = 'block';
                        LoderBtn.style.display = 'none';
                    });
            }
        }

    }

    onSubmitLogin = (e) => {

        e.preventDefault();

        let divName = document.getElementById('div-name');
        let LoderBtn = document.getElementById('btn-loader');
        divName.style.display = 'none';
        LoderBtn.style.display = 'block';

        // let { email, password } = this.state;

        let loginEmail = document.getElementById('login-email').value;
        let loginPassword = document.getElementById('login-password').value;

        let patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (loginEmail === "") {
            alert("Please enter Your Email !");
            divName.style.display = 'block';
            LoderBtn.style.display = 'none';
        } else if (loginPassword === "") {
            alert("Please enter Your Password !");
            divName.style.display = 'block';
            LoderBtn.style.display = 'none';
        } else {
            if (!patternEmail.test(loginEmail)) {
                alert("Please enter a valid Email-id");
                divName.style.display = 'block';
                LoderBtn.style.display = 'none';
            } else {
                firebase.auth().signInWithEmailAndPassword(loginEmail, loginPassword).then((user) => {
                    this.context.login(true);
                    divName.style.display = 'block';
                    LoderBtn.style.display = 'none';
                })
                    .catch(() => {
                        // let errorCode = error.code;
                        // let errorMessage = error.message;
                        alert("Please Enter Valid Email or Password");
                        divName.style.display = 'block';
                        LoderBtn.style.display = 'none';

                    });
            }
        }



    }

    onForgetPassword() {
        // const { email } = this.state;
        let loginEmail = document.getElementById('login-email').value;
        let patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (loginEmail === "") {
            alert("Please enter a valid email address.")
        } else {
            if (!patternEmail.test(loginEmail)) {
                alert("Please enter a valid Email-id");
            } else {
                firebase.auth().sendPasswordResetEmail(loginEmail)
                    .then(() => {
                        alert('Please check your email and reset password there.')
                    })
                    .catch((error) => {
                        // Error occurred. Inspect error.code.
                        // console.log("ERROR :: ", error)
                    });
            }
        }

    }

    render() {

        return (
            // <div>
            //   Checked
            // </div>
            <div className="main-body">
                <div className="container" id="container">
                    <div className="form-container sign-up-container">
                        <form onSubmit={this.onAddSubmit}>
                            <h1>Create Account</h1>
                            <span>or use your email for registration</span>
                            <input
                                id='reg-email'
                                // value={email}
                                onChange={this.handleInputChange}
                                name="email"
                                type="email"
                                placeholder="Email"
                            />

                            <input
                                id='reg-password'
                                // value={password}
                                onChange={this.handleInputChange}
                                name="password"
                                type="password"
                                placeholder="Password"
                            />
                            <button className="login-button">
                                <div id="name-signup" style={{ display: "block" }}>Sign Up</div>
                                <div className="loader" id="btn-loadersignup" style={{ display: "none" }} />
                            </button>
                        </form>
                    </div>
                    <div className="form-container sign-in-container">

                        <form action="#">
                            <h1 className="signin-text">Sign in</h1>
                            <input
                                // value={email}
                                id="login-email"
                                onChange={this.handleInputChange}
                                type="email"
                                placeholder="Email"
                                name="email"
                            />
                            <input
                                id="login-password"
                                // value={password}
                                onChange={this.handleInputChange}
                                type="password"
                                placeholder="Password"
                                name="password"
                            />
                            <label className="forgot" onClick={(e) => this.onForgetPassword(e)}>Forgot your password?</label>
                            <button className="login-button" onClick={(e) => this.onSubmitLogin(e)}>
                                <div id="div-name" style={{ display: "block" }}>Login</div>
                                <div className="loader" id="btn-loader" style={{ display: "none" }} />
                            </button>
                        </form>
                    </div>
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left ">
                                <h1>Hello friend!</h1>
                                <p className="login-text height-p">Login to your account</p>
                                <button className="login-button login_button" id="login">Login</button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1>Welcome Back!</h1>
                                <p className="textcenter height-p marginbottomtext">Dont have an account? <br /> Join us now</p>
                                <button className="login-button signup_button" id="signUp" style={{ display: 'none' }}>
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default Login;