import React from 'react';
// import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import RouteContainer from './route-container';
import { UserProvider } from './context/Context';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

const App = () => (
  <UserProvider>
    <Router history={history}>
      <RouteContainer />
    </Router>
  </UserProvider>
);
render(<App />, document.querySelector('#root'));




// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
