/* eslint-disable no-unused-vars */

import React from 'react';
import { UserContext } from './context/Context';
import firebase from './common/firebase';
import Login from './common/login';
import './App.css';
import './css/search.css';

// ---------COMMON FOLDER COMPONENT-------------
import Search from './common/search';

const FooterItem = props => (
    <li className={`${props.class} fbca`}>
        <img
            src={props.src}
            alt=""
            className={`primary-actions__icon cursor--pointer ${props.classImg}`}
        />
    </li>
);

class Main extends React.Component {
    static contextType = UserContext;
    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.context.login(true);
            } else {
                this.context.logout(false);
            }
        })
    }
    render() {
        return (
            <div>
                {this.context.isUserLoggedIn &&
                    <footer className="footer footer-top">
                        <ul className="primary-actions ul--reset">
                            <FooterItem
                                class="primary-actions__item "
                                classImg="primary-actions__item--home-logo"
                                src="https://d2mjqh1irji0ds.cloudfront.net/image/public/discovery/icons/generic/melzo-logo-white.svg"
                                desc="Home button"
                            />
                            <Search />
                        </ul>
                    </footer>
                }

                {!this.context.isUserLoggedIn &&
                    <Login />
                }

            </div>
        );
    }
}



export default Main;